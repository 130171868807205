<template>
  <div class="hello">
    <img alt="mock logo" :src="img">
    <h3>{{ message }}</h3>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  data(){
    return{
      img:"",
      message:""
    }
  },
  created:function(){
        var that=this;
        this.spring.get("/userAndOrder",{params:{id:2}})
        .then(function(response){
          that.img=response.data.img;
          that.message=response.data.message;
        })
      },
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
